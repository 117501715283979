body {
  color: #0c0c0cba;
  overflow-x: hidden;
  height: 100%;
  background-color: #dee2e6d1;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 4px 8px 0px #757575;
  border-radius: 6px;
}

.card2 {
  margin: 0px 40px;
}

.logoLogin {
  margin-top: 20px;
  margin-left: 50px;
  margin-bottom: 10px;
}

.image {
  width: 620px;
}

.border-line {
  border-right: 0px;
}

.facebook {
  background-color: #3b5998;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.twitter {
  background-color: #1da1f2;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.linkedin {
  background-color: #2867b2;
  color: #fff;
  font-size: 18px;
  padding-top: 5px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.line {
  height: 1px;
  width: 45%;
  background-color: #e0e0e0;
  margin-top: 10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

.text-sm {
  font-size: 14px !important;
}

::placeholder {
  color: #bdbdbd;
  opacity: 1;
  font-weight: 300;
}

:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

::-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 300;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #2c3e50;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #304ffe;
  outline-width: 0;
}

button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-blue {
  background-color: #0c0c0cba;
  width: 150px;
  color: #ffffff;
  border-radius: 2px;
}

.btn-blue:hover {
  background-color: #0c0c0c85;
  cursor: pointer;
  color: #ffffff;
}

.bg-blue {
  color: #fff;
  background-color: #0c0c0cba;
}

@media screen and (max-width: 991px) {
  .logoLogin {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}

.load {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 30%;
  left: 45%;
  color: blue;
}

/* custom.css */
/* Mostrar apenas a borda inferior para o input */
.bordasInferiorInput {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #ccc; /* Adicione a cor desejada para a borda inferior */
  outline: none; /* Remover a borda quando o input estiver focado */
}

/* Remover a borda que aparece ao clicar no input */
.bordasInferiorInput:focus {
  box-shadow: 0 0 0 #ccc;
  border: none;
  border-bottom: 1px solid #3165f5;
  outline: none; /* Remover a borda padrão do foco */
}
.acoes {
  white-space: nowrap;
  text-align: right;
}
.botaoTransparente {
  border: none;
  background-color: transparent;
}
.label-float {
  position: relative;
  padding-top: 13px;
}

.label-float input {
  /* border: 1px solid lightgrey; */
  border: none;
  border-bottom: 1px solid #3165f5;
  /* border-radius: 5px; */
  outline: none;
  min-width: 250px;
  padding: 15px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
}

.label-float input:focus {
  /* border: 2px solid #3951b2; */
  border: none;
  box-shadow: 0 0 0 #ccc;
  border-bottom: 1px solid #3165f5;
  outline: none; /* Remover a borda padrão do foco */

  /*
  */
}

.label-float input::placeholder {
  color: transparent;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px;
  box-sizing: border-box;
}

.label-float input:required:invalid + label {
  color: red;
}

.label-float input:focus:required:invalid {
  border: 2px solid red;
}

.label-float input:required:invalid + label:before {
  content: "*";
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 0;
  color: #3951b2;
}

.tamanhoLegend {
  font-size: 16px;
}

.margemLinha{
  margin-top: 0px;
  margin-bottom: -10px;
  padding-bottom: -10px;
}
.cardSalasAtendimento {
  width: auto;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  position: relative;
  padding: 4px;
  border-color: #304ffe;
  border: solid 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.cardSalasAtendimento:hover {
  top: -4px;
  box-shadow: 0 4px 8px #999;
}

.evento-customizado {
  min-height: 50px; /* Ajuste a altura mínima conforme necessário */
  line-height: 1.2; /* Ajuste a altura da linha conforme necessário */
}
