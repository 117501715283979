.fc-timegrid-slots td {
  font-weight: bold;
  /* min-height: 200px;
  height: 500px; */
}
.fc-daygrid-weekday {
  text-transform: capitalize; /* Torna a primeira letra de cada palavra maiúscula */
  font-weight: bold;
}

.fc-timegrid-axis {
  text-transform: capitalize; /* Torna a primeira letra de cada palavra maiúscula */
  font-weight: bold;
}
.fc-daygrid-day-number {
  font-weight: bold;
  font-size: 16px;
}
.fc-toolbar .fc-prev-button {
  margin-right: 10px; /* Ajuste o valor conforme necessário para o espaçamento desejado */
}

.fc-toolbar .fc-next-button {
  margin-left: 10px; /* Ajuste o valor conforme necessário para o espaçamento desejado */
}
.fc-time-grid-event {
  margin: 1px; /* Espaçamento interno para evitar cortes */
  /*min-height: 300px;  Ajuste a altura mínima conforme necessário */
 /* line-height: 20;  Ajuste a altura da linha conforme necessário */
}
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  min-height: 5rem;
}
/* .fc-direction-ltr .fc-timegrid-slot-label-frame.linha-com-eventos {
  height: 50rem;  Altura padrão quando não há eventos 
}

.fc-time-grid {
   min-height: 500px; Ajuste a altura conforme necessário 
} */
.fc-toolbar-button {
  margin-right: 5px; /* Adicione o espaçamento desejado entre os botões */
}

.dragging-event {
  cursor: grabbing;
}

.custom-datalist-input {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 10px 14px;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.custom-datalist-input:focus {
  border-color: #1976d2; /* cor do foco */
  box-shadow: 0 0 0 0.2rem rgba(25, 118, 210, 0.25); /* sombra do foco */
}